<template>
  <section class="header">
    <div class="content container">
      <h1>{{ product.name }}</h1>
      <h4>{{ product_parent.type }}</h4>
    </div>
  </section>
  <section id="product-detail">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <h1>{{ product.name }}</h1>
          <p>{{product.short_description}}</p>
          <div class="underline_highlight"></div>

          <section class="mt-5" v-html="product.description"/>

          <section class="mt-5">
            <h6>Keywords</h6>
            <p>{{product.keywords}}</p>
          </section>
        </div>
        <div class="col-md-5 offset-md-1">
          <img :src="getImage" class="img-fluid" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "ProductDetail",
  data() {
    return {
      slug: String,
      product: {},
      product_parent: {},
    };
  },
  methods: {
    getProduct() {
      const that = this;
      const all_products = this.$store.state.products;

      all_products.every((type) => {
        const products = type.products;
        const flag = products.every((product) => {
          if (product.slug === that.productSlug) {
            that.product = product;
            return false;
          }
          return true;
        });
        if (!flag) {
          that.product_parent = type;
        }
        return flag;
      });
    },
  },
  computed: {
    productSlug() {
      const params = this.$route.params;
      return params.slug;
    },
    getImage() {
      return require("../assets/products/" + this.product.photo);
    },
  },
  created() {
    const params = this.$route.params;
    if (params.slug === "")
      this.$router.replace({ name: "Products", params: { type: "" } });
    else this.getProduct();
  },
};
</script>

<style scoped lang="scss">
.header {
  height: 70vh;
  background-color: red;
  margin-top: -6.5rem;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.5)),
    url("../assets/images/header/5.jpg");
  background-size: cover;
  display: flex;
  align-items: center;

  .content {
    color: white;
    text-align: left;
    margin-top: 4rem;

    h1 {
      font-size: 3rem;
      width: 70%;
      border-left: 8px solid $theme-color;
      padding-left: 1rem;
    }

    p {
      width: 50%;
      margin-top: 1.2rem;
      color: white;
    }
  }

  @media (min-width: 1400px) {
    height: 50vh;
  }
}

#product-detail {
  padding: 6rem 0;
}
</style>
